/*
 * @Description: 文件内容描述
 * @Date: 2022-07-27 11:56:24
 * @LastEditors: 无言的对话
 * @LastEditTime: 2022-07-27 12:01:04
 * @FilePath: \admin\src\permission.js
 */
import router from './router'
import NProgress from 'nprogress' // progress bar

import store from '@/store/index'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach((to, from, next) => {
  if (!store.state.UserToken) {
    // 未登录  页面是否需要登录
    if (to.matched.length > 0 && !to.matched.some((record) => record.meta.requiresAuth)) {
      next()
    } else {
      next({
        path: '/login',
      })
    }
  } else {
    // 用户已经登录  路由的访问权限
    if (!store.state.permission.permissionList) {
      store.dispatch('permission/FETCH_PERMISSION').then(() => {
        next({  
          path: to.path,
        }) 
      })
    } else {
      // store存在权限
      if (to.path !== '/login') {
        next()
      } else {
        next(from.fullPath)
      }
    }
  }
})
