/**
 *
 * @param {Array} userRouter 后台返回的路由权限
 * @param {Array} allRouter 前端配置好的路由权限数据
 * @return {Array} realRoutes 过滤之后的符合条件的路由
 */

export function recursionRouter(userRouter = [], allRouter = []) {
  let realRoutes = []

  if (Array.isArray(userRouter)) {
    // allRouter.forEach((v, i) => {
    //   userRouter.forEach((item, index) => {
    //     if (item?.remark.toLowerCase() === v.name.toLowerCase()) {
    //       if (item.children && item.children.length > 0) {
    //         v.children = recursionRouter(item.children, v.children)
    //       }
    //       realRoutes.push(v)
    //     }
    //   })
    // })

    userRouter?.forEach((item) => {
      let index = allRouter?.findIndex((i) => i.path.toLowerCase() == item?.path.toLowerCase())

      if (index != -1) {
        allRouter[index].meta.name = item.name
        allRouter[index].meta.icon = item.icon
        let obg = allRouter[index]
        realRoutes.push({ ...obg, children: recursionRouter(item.children, obg.children) })
      }
    })
  }

  return realRoutes
}

export function setDefaultRoute(routes) {
  routes.forEach((v, i) => {
    if (v.children && v.children.length > 0) {
      v.redirect = { name: v.children[0].name }
      setDefaultRoute(v.children)
    }
  })
}
