/*
 * @Description: 文件内容描述
 * @Date: 2022-07-27 10:51:12
 * @LastEditors: 无言的对话
 * @LastEditTime: 2022-08-03 11:00:11
 * @FilePath: \admin\src\utils\request.js
 */
import axios from 'axios'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { serverSrc } from '@/api/config'
import store from '@/store/index'
import router from '@/router'
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: serverSrc,
  timeout: 60000, // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  console.log('error:', error)
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token

    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message,
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: '登录过期',
        description: '即将前往登录页面重新登录！',
      })
      setTimeout(() => {
        window.localStorage.clear()
        store.commit('permission/CLEAR_MENU')
        router.replace({ path: '/login' })
      }, 1000)
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['Authorization'] = token
  }
  return config
}, errorHandler)

// 响应拦截
request.interceptors.response.use((response) => {
  if (response.data.success) {
    return Promise.resolve(response.data)
  } else if (response.status === 401) {
  } else {
    return Promise.reject(response.data)
  }
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  },
}

export default request

export { installer as VueAxios, request as axios }
