/*
 * @Description: 路由文件
 * @Date: 2022-07-26 18:13:49
 * @LastEditors: 无言的对话
 * @LastEditTime: 2022-07-27 13:34:27
 * @FilePath: \admin\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import { BasicLayout, BlankLayout } from '@/layouts/index'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

/**
 * 基础路由
 * @type { *[] }
 */
export const routes = [
  {
    path: '/Login',
    name: 'Login',
    meta: { name: '登录' },
    component: () => import('../views/public/Login.vue'),
  },
]

// 准备动态加载的路由
export const DynamicRoutes = [
  {
    path: '',
    name: 'index',
    component: BasicLayout,
    redirect: '/',
    meta: { name: '首页', requiresAuth: true },
    children: [
      // {
      //   path: '/systemManage',
      //   name: 'systemManage',
      //   meta: { name: '系统管理', icon: 'safety-certificate', requiresAuth: true },
      //   children: [
      //     {
      //       path: '/userList',
      //       name: 'userList',
      //       meta: { name: '用户管理', icon: 'user', requiresAuth: true },
      //       component: () => import('../views/system/userlist.vue'),
      //     },
      //   ],
      //   component: () => import('../views/system/Index.vue'),
      // },
    ],
  },  
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
})

export default router
