// 静态路由
export const asyncRouterMap = [
  {
    path: '/dataBoard',
    name: 'dataBoard',
    meta: { name: '数据面板', icon: 'user', requiresAuth: true },
    component: () => import('../views/dataBoard/index.vue'),
    children: [
      {
        path: '/homeHeatMap',
        name: 'homeHeatMap',
        meta: { name: '', icon: 'user', requiresAuth: true },
        component: () => import('../views/dataBoard/homeHeatMap/index.vue'),
      },
      {
        path: '/basic',
        name: 'basic',
        meta: { name: '', icon: 'user', requiresAuth: true },
        component: () => import('../views/dataBoard/basic/index.vue'),
      },
      {
        path: '/courseData',
        name: 'courseData',
        meta: { name: '', icon: 'user', requiresAuth: true },
        component: () => import('../views/dataBoard/course/index.vue'),
      },
      {
        path: '/caseData',
        name: 'caseData',
        meta: { name: '', icon: 'user', requiresAuth: true },
        component: () => import('../views/dataBoard/case/index.vue'),
      },
      {
        path: '/user',
        name: 'user',
        meta: { name: '', icon: 'user', requiresAuth: true },
        component: () => import('../views/dataBoard/user/index.vue'),
      },
      {
        path: '/operation',
        name: 'operation',
        meta: { name: '', icon: 'user', requiresAuth: true },
        component: () => import('../views/dataBoard/operation/index.vue'),
      },
      {
        path: '/search',
        name: 'search',
        meta: { name: '', icon: 'user', requiresAuth: true },
        component: () => import('../views/dataBoard/search/index.vue'),
      },

    ]
  },
  {
    path: '/userManagement',
    name: 'userManagement',
    meta: { name: '用户管理', icon: 'user', requiresAuth: true },
    component: () => import('../views/userManagement/index.vue'),
    children: [
      {
        path: '/customer',
        name: 'customer',
        meta: { name: '客户管理', icon: 'user', requiresAuth: true },
        component: () => import('../views/userManagement/customer/index.vue'),
      },
      {
        path: '/operator',
        name: 'operator',
        meta: { name: '运营人员管理', icon: 'user', requiresAuth: true },
        component: () => import('../views/userManagement/operator/index.vue'),
      }
    ]
  },
  {
    path: '/classificationManagement',
    name: 'classificationManagement',
    meta: { name: '分类管理', icon: 'user', requiresAuth: true },
    component: () => import('../views/classificationManagement/index.vue'),
    children: [
      {
        path: '/serviceStaff',
        name: 'serviceStaff',
        meta: { name: '服务人员管理', icon: 'user', requiresAuth: true },
        component: () => import('../views/classificationManagement/serviceStaff/index.vue'),
      },
      {
        path: '/customerBrandClassification',
        name: '/customerBrandClassification',
        meta: { name: '客户品牌管理', icon: 'user', requiresAuth: true },
        component: () => import('../views/classificationManagement/customer/index.vue'),
      },
      {
        path: '/caseClassification',
        name: 'caseClassification',
        meta: { name: '案例分类管理', icon: 'user', requiresAuth: true },
        component: () => import('../views/classificationManagement/caseClassification/index.vue'),
      },
      {
        path: '/shareClassification',
        name: 'shareClassification',
        meta: { name: '文章分类管理', icon: 'user', requiresAuth: true },
        component: () => import('../views/classificationManagement/share/index.vue'),
      }
    ]
  },
  {
    path: '/caseManagement',
    name: 'caseManagement',
    meta: { name: '案例管理', icon: 'user', requiresAuth: true },
    component: () => import('../views/caseManagement/index.vue'),
  },
  {
    path: '/contentManagement',
    name: 'contentManagement',
    meta: { name: '内容管理', icon: 'user', requiresAuth: true },
    component: () => import('../views/contentManagement/index.vue'),
    children: [
      {
        path: '/notice',
        name: 'notice',
        meta: { name: '公告管理', icon: 'user', requiresAuth: true },
        component: () => import('../views/contentManagement/notice/index.vue'),
      },
      {
        path: '/dialog',
        name: 'dialog',
        meta: { name: '开屏弹窗管理', icon: 'user', requiresAuth: true },
        component: () => import('../views/contentManagement/dialog/index.vue'),
      },
      {
        path: '/course',
        name: 'course',
        meta: { name: '装修教程管理', icon: 'user', requiresAuth: true },
        component: () => import('../views/contentManagement/course/index.vue'),
      },
      {
        path: '/case',
        name: 'case',
        meta: { name: '分享文章管理', icon: 'user', requiresAuth: true },
        component: () => import('../views/contentManagement/case/index.vue'),
      },
      {
        path: '/banner',
        name: 'banner',
        meta: { name: 'banner管理', icon: 'user', requiresAuth: true },
        component: () => import('../views/contentManagement/banner/index.vue'),
      },
    ]
  },
  {
    path: '/systemManage',
    name: 'systemManage',
    meta: { name: '系统管理', icon: 'safety-certificate', requiresAuth: true },
    component: () => import('../views/system/Index.vue'),
    children: [
      {
        path: '/roleList',
        name: 'roleList',
        meta: { name: '角色管理', icon: 'team', requiresAuth: true },
        component: () => import('../views/system/roleList.vue'),
      },
      {
        path: '/menu',
        name: 'menu',
        meta: { name: '菜单管理', icon: 'unordered-list', requiresAuth: true },
        component: () => import('../views/system/menuList.vue'),
      },
    ],
  },
  {
    path: '/feedback',
    name: 'feedback',
    meta: { name: '用户反馈', icon: 'safety-certificate', requiresAuth: true },
    component: () => import('../views/feedback/index.vue'),
  },
]
