<!--
 * @Description: 文件内容描述
 * @Date: 2022-07-27 09:08:08
 * @LastEditors: 无言的对话
 * @LastEditTime: 2022-07-27 10:01:55
 * @FilePath: \admin\src\layouts\BlankLayout.vue
-->
<template>
  <div class="BlankLayout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "BlankLayout",
};
</script>

<style scoped>
.BlankLayout {
  min-height: 100vh;
}
</style>
