<!--
 * @Description: 文件内容描述
 * @Date: 2022-07-27 09:08:08
 * @LastEditors: 无言的对话
 * @LastEditTime: 2022-07-27 13:35:31
 * @FilePath: \admin\src\layouts\BasicLayout.vue
-->
<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed" class="layoutLeft" :trigger="null" collapsible>
      <span slot="overflowedIndicator">45</span>
      <div class="logo">
        <img src="../assets/images/logo.png" alt="">
        <p>鹿宾</p>
      </div>
      <a-menu theme="dark" mode="inline" :selectedKeys="current" @click="onClickMenu">
        <template v-for="item in menus">
          <!-- 第一层 -->
          <template v-if="item.children">
            <a-sub-menu :key="item.name">
              <span slot="title">
                <a-icon :type="item.meta.icon" />
                <span>{{ item.meta.name }}</span>
              </span>

              <template v-for="subitem in item.children">
                <!-- 第二层 -->
                <template v-if="subitem.children">
                  <a-sub-menu :key="subitem.name">
                    <span slot="title">
                      <a-icon :type="subitem.meta.icon" />
                      <span>{{ subitem.meta.name }}</span>
                    </span>
                    <template v-for="subsubItem in subitem.children">
                      <!-- 第三层 -->
                      <template>
                        <a-menu-item :key="subsubItem.name">
                          <a-icon :type="subsubItem.meta.icon" />
                          {{ subsubItem.meta.name }}
                        </a-menu-item>
                      </template>
                    </template>
                  </a-sub-menu>
                </template>
                <!-- 第二层 -->
                <template v-else>
                  <a-menu-item :key="subitem.name">
                    <a-icon :type="subitem.meta.icon" />
                    {{ subitem.meta.name }}
                  </a-menu-item>
                </template>
              </template>
            </a-sub-menu>
          </template>
          <!-- 第一层 -->
          <template v-else>
            <a-menu-item :key="item.name">
              <a-icon :type="item.meta.icon" />
              {{ item.meta.name }}
              <a-badge :count="$store.state.feedback.feedbackNumber" v-if="item.name==='feedback'"></a-badge>
            </a-menu-item>
          </template>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <!-- <a-spin :spinning="true"> -->

      <a-layout-header style="background: #fff; padding: 0 24px 0 0; display: flex; justify-content: space-between">
        <div style="display: flex;padding-left:24px" >
          <!-- <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)" /> -->
          <h2>{{ menuTitle }}</h2>
        </div>
        <div class="userInfo">
          <a-avatar size="large"
            :style="{ verticalAlign: 'middle', backgroundColor: 'rgb(24, 144, 255)', flexShrink: '0' }" icon="user" />

          <a-dropdown style="margin-right: 24px">
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              <h4 class="nickname">{{ nickname }}</h4>
              <a-icon class="icon" type="down" />
            </a>

            <a-menu slot="overlay">
              <a-menu-item key="1" @click="signOut">
                <a-icon type="user" />退出登录
              </a-menu-item>
            </a-menu>
            <!-- <a-menu slot="overlay"> </a-menu> -->
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content class="layoutConetent">
        <router-view />
      </a-layout-content>
      <!-- </a-spin> -->
    </a-layout>
  </a-layout>
</template>

<script>
import userInfo from '@/store/modules/userInfo'
import { mapState } from 'vuex'

export default {
  name: 'BasicLayout',
  components: {},
  data() {
    return {
      menus: [],
      collapsed: false,
      menuTitle: '', // 菜单页面的标题
      nickname: '',
      current: [],
    }
  },
  computed: {
    ...mapState('permission', ['sidebarMenu', 'currentMenu']),
    ...mapState(['userInfo']),
  },
  beforeUpdate() {
    this.getCurrentRoute() // 获取当前路由信息，并更新
  },
  created() {
    this.$store.commit('GET_NUMBER')
    this.getCurrentRoute() // 获取当前路由信息，并更新
    const userInfo = JSON.parse(this.userInfo.userInfo)
    this.nickname = userInfo.nickname

    const newMenu = (arr) => {
      arr.find((item) => {
        if (item.children && item.children.length > 0) {
          item.children = newMenu(item.children)
        }
        if (item.children && item.children.length == 0) {
          delete item.children
        }
      })

      return arr
    }

    this.menus = newMenu(this.sidebarMenu)
  },
  mounted() { },
  methods: {

    
    /**
     * 点击菜单
     */
    onClickMenu({ item, key, keyPath }) {
      console.log('item', item)
      this.$router.push(key)
    },

    /**
     * 获取当前路由信息，并更新
     */
    getCurrentRoute() {
      this.menuTitle = this.$route.meta.name // 更新菜单标题

      // 更新菜单当前选择数组
      const selectkey = []
      selectkey.push(this.$route.name)
      this.current = selectkey
    },

    /**
     * 退出登录
     */
    signOut() {
      window.localStorage.clear()
      this.$store.commit('permission/CLEAR_MENU')
      this.$router.push('/login')
    },
  },
}
</script>

<style lang="less" scoped>
.layoutLeft {
  min-height: 100vh;
}

.layoutConetent {
  position: relative;
  margin: 24px 26px;
  padding: 24px;
  background: #ffffff;
  min-height: 280px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  // background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  p {
    font-weight: bold;
    margin-left: 15px;
    font-size: 22px;
    letter-spacing: 12px;
    padding-top: 10px;
  }

  img {
    width: 50px;
  }
}

.userInfo {
  // width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-dropdown-link {
    display: flex;
    align-items: center;
    margin: 0 10px;

    .icon {
      color: #666;
      font-size: 14px !important;
      margin-left: 4px;
    }
  }

  .nickname {}
}
</style>
