import request from '@/utils/request'

const userApi = {
  adminLogin: '/admin/login', // 后台项目登录
  getAdminUserList: '/adminUser/getAdminUserList', // 获取用户列表
  addOrUpdate: '/adminUser/addOrUpdate', // 添加/修改用户
  deleteUser: '/adminUser/delete', // 删除用户
  getRoleList: '/role/getRoleList', // 获取角色列表
  getMenuList: '/adminMenu/getMenuList', // 获取菜单表
  addOrUpdateMenu: '/adminMenu/addOrUpdate', // 添加/修改菜单
  deleteMenu: '/adminMenu/delete', // 删除菜单
  getAuthMenu: '/adminMenu/getAuthMenu', //获取用户授权菜单列表,
  addOrUpdateRole: '/role/addOrUpdate', // 添加/修改角色
  deleteRole: '/role/delete', // 删除菜单
  getAuthAccessOnRole: '/role/getAuthAccessOnRole', // 获取角色上的授权列表
  setAuthAccessOnRole: '/role/setAuthAccessOnRole', // 角色授权操作
}

// ======================================================用户登录============================================================

/**
 * 后台登录
 * @param {*} params
 * @returns
 */
export function adminLogin(params) {
  return request({
    url: userApi.adminLogin,
    method: 'POST',
    data: params,
  })
}

// ======================================================用户管理============================================================

/**
 * 获取用户列表
 * @param {*} params
 * @returns
 */
export function getUserList(params) {
  return request({
    url: userApi.getAdminUserList,
    method: 'POST',
    data: params,
  })
}

/**
 * 添加/修改用户
 * @param {*} params
 * @returns
 */
export function editUser(params) {
  return request({
    url: userApi.addOrUpdate,
    method: 'POST',
    data: params,
  })
}

/**
 * 删除用户
 * @param {*} params
 * @returns
 */
export function deleteUser(params) {
  return request({
    url: userApi.deleteUser,
    method: 'get',
    params,
  })
}

// ======================================================用户角色============================================================

/**
 * 获取角色表
 * @param {*} params
 * @returns
 */
export function getRoleList(params) {
  return request({
    url: userApi.getRoleList,
    method: 'get',
    params,
  })
}

/**
 * 添加/修改角色
 * @param {*} params
 * @returns
 */
export function editRole(params) {
  return request({
    url: userApi.addOrUpdateRole,
    method: 'POST',
    data: params,
  })
}

/**
 * 删除角色
 * @param {*} params
 * @returns
 */
export function deleteRole(params) {
  return request({
    url: userApi.deleteRole,
    method: 'get',
    params,
  })
}

/**
 * 获取角色上的授权列表
 * @param {*} params
 * @returns
 */
export function getAuthAccessOnRole(params) {
  return request({
    url: userApi.getAuthAccessOnRole,
    method: 'get',
    params,
  })
}

/**
 * 获取角色上的授权列表
 * @param {*} params
 * @returns
 */
export function setAuthAccessOnRole(params) {
  return request({
    url: userApi.setAuthAccessOnRole,
    method: 'POST',
    data: params,
  })
}

// ======================================================菜单管理============================================================

/**
 * 获取菜单表
 * @param {*} params
 * @returns
 */
export function getMenuList(params) {
  return request({
    url: userApi.getMenuList,
    method: 'get',
    params,
  })
}

/**
 * 获取用户授权菜单列表
 * @param {*} params
 * @returns
 */
export function getAuthMenu(params) {
  return request({
    url: userApi.getAuthMenu,
    method: 'get',
    params,
  })
}

/**
 * 添加/修改菜单
 * @param {*} params
 * @returns
 */
export function addOrUpdateMenu(params) {
  return request({
    url: userApi.addOrUpdateMenu,
    method: 'POST',
    data: params,
  })
}

/**
 * 删除菜单
 * @param {*} params
 * @returns
 */
export function deleteMenu(params) {
  return request({
    url: userApi.deleteMenu,
    method: 'get',
    params,
  })
}



