import Vue from 'vue'
import Vuex from 'vuex'
import state from './defaultState'
import mutations from './mutations'
import permission from './modules/permission'
import userInfo from './modules/userInfo'
import feedback from './modules/feedback'
Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    permission,
    userInfo,
    feedback,
  },
})
