export default {
  state: {
    get userInfo() {
      return localStorage.getItem('userInfo')
    },
    set userInfo(value) {
      localStorage.setItem('userInfo', value)
    },
  },
  mutations: {
    SET_USERINFO(state, info) {
      state.userInfo = info
    },

    CLEAR_USERINFO(state) {
      state.userInfo = {}
    },
  },
}
