<!--
 * @Description: 入口主文件
 * @Date: 2022-07-26 18:13:49
 * @LastEditors: 无言的对话
 * @LastEditTime: 2022-07-27 13:53:53
 * @FilePath: \admin\src\App.vue
-->
<template>
  <div id="app">
    <a-config-provider :locale="zh_CN">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
// import { asyncRouterMap } from '@/config/router.config'
export default {
  created() {
    // const menu_list = []
    // this.$store.commit('set_menu_list', asyncRouterMap)
  },
  data() {
    return {
      zh_CN,
    }
  },
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
  