import { getAuthMenu } from '../../api/user'
import router, { DynamicRoutes } from '../../router/index'
import { asyncRouterMap } from '@/config/router.config'
import { recursionRouter, setDefaultRoute } from '../../utils/recursion-router'

import store from '@/store/index'

export default {
  namespaced: true,
  state: {
    permissionList: null,
    sidebarMenu: [], // 导航菜单
    currentMenu: '', // 高亮
  },
  getters: {},
  mutations: {
    SET_PERMISSION(state, routes) {
      state.permissionList = routes
    },
    CLEAR_PERMSSION(state) {
      state.permissionList = null
    },
    SET_MENU(state, menu) {
      state.sidebarMenu = menu
    },
    CLEAR_MENU(state) {
      state.sidebarMenu = []
    },
  },
  // 异步访问
  actions: {
    async FETCH_PERMISSION({ commit, state }) {
      let permissionList = await getAuthMenu()

      // 筛选

      console.log('后端permissionList', permissionList)

      if (permissionList.data == []) {
        window.localStorage.clear()
        store.commit('permission/CLEAR_MENU')
        router.replace({ path: '/login' })
      } else {
        let routes = recursionRouter(permissionList.data, asyncRouterMap)

        let MainContainer = DynamicRoutes.find((v) => v.path === '')

        let children = MainContainer.children

        children.push(...routes)
        // 生成菜单
        commit('SET_MENU', children)
        // 设置默认路由
        setDefaultRoute([MainContainer])
        // 初始化路由
        let initialRoutes = router.options.routes
        router.addRoutes(DynamicRoutes)
        commit('SET_PERMISSION', [...initialRoutes, ...DynamicRoutes])
      }
    },
  },
}
