
import request from '@/utils/request'

// 获取反馈
export function getFeedback(params) {
  return request({
    url: '/opinion/getByPage',
    method: 'post',
    data:params,
  })
}

// 获取反馈
export function getFeedbackNumber(params) {
  return request({
    url: '/opinion/untreated',
    method: 'get',
  })
}

// 处理反馈
export function handleFeedback(params) {
  return request({
    url: `/opinion/processed/${params}`,
    method: 'get',
  })
}


