/*
 * @Description: 文件内容描述
 * @Date: 2022-07-26 18:13:49
 * @LastEditors: 无言的对话
 * @LastEditTime: 2022-07-27 11:59:44
 * @FilePath: \admin\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import moment from 'moment';
import 'ant-design-vue/dist/antd.css'
import './permission'              
import md5 from 'js-md5'
import '@/assets/css/reset.css'
import * as echarts from 'echarts'
import vcolorpicker from 'vcolorpicker'
Vue.use(vcolorpicker)

 
Vue.prototype.$echarts = echarts 
Vue.prototype.$moment = moment 

Vue.prototype.$md5 = md5

Vue.config.productionTip = false
Vue.use(Antd)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
