import { getFeedbackNumber } from '@/api/feedback'
export default {
    state: {
        feedbackNumber: null,
    },
    mutations: {
        GET_NUMBER: async (state) => {
            try {
                const res = await getFeedbackNumber()
                state.feedbackNumber = res.data
            } catch (e) {
                console.log(e, 'GET_NUMBER')
            }
        }
    },
}
